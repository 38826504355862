exports.components = {
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-markdown-remark-frontmatter-slug-tsx": () => import("./../../../src/pages/legal/{markdownRemark.frontmatter__slug}.tsx" /* webpackChunkName: "component---src-pages-legal-markdown-remark-frontmatter-slug-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-reservation-channels-tsx": () => import("./../../../src/pages/reservation-channels.tsx" /* webpackChunkName: "component---src-pages-reservation-channels-tsx" */),
  "component---src-pages-smart-table-management-tsx": () => import("./../../../src/pages/smart-table-management.tsx" /* webpackChunkName: "component---src-pages-smart-table-management-tsx" */),
  "component---src-pages-why-reslify-tsx": () => import("./../../../src/pages/why-reslify.tsx" /* webpackChunkName: "component---src-pages-why-reslify-tsx" */)
}

