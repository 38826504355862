import i18nConfigs from '../../i18n/config.json';

export type Lang = 'en' | 'tr';

export interface Language {
  code: Lang;
  hrefLang: string;
  name: string;
  localName: string;
  langDir: Direction;
  dateFormat: string;
}

export type Direction = 'rtl' | 'ltr' | 'auto';

export type Languages = Record<Lang, Language>;
export const defaultLanguage: Lang = 'en';

// same data as in the `config.json` but indexed by language code
const languages: Languages = i18nConfigs.reduce((result, config) => {
  return { ...result, [config.code]: config };
}, {} as Languages);

export const supportedLanguages = Object.keys(languages) as Array<Lang>;

export const languagePresentation: Record<Lang, string> = {
  en: 'United States (English)',
  tr: 'Türkiye (Türkçe)'
};
