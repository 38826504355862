/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
// FormatJS Polyfill imports - Used for intl number formatting
import '@formatjs/intl-locale/polyfill';
import '@formatjs/intl-numberformat/locale-data/en';
import '@formatjs/intl-numberformat/polyfill';
import browserLang from 'browser-lang';
import { GatsbyBrowser, withPrefix } from 'gatsby';
import React from 'react';



import { IS_DEV } from './src/utils/env.constants';
import { isLanguage } from './src/utils/is-language';
import { defaultLanguage, supportedLanguages } from './src/utils/language.constants';


// Prevents <Layout/> from unmounting on page transitions
// https://www.gatsbyjs.com/docs/layout-components/#how-to-prevent-layout-components-from-unmounting
// @ts-ignore: returning `null` is not accepted by the `GatsbyBrowser` type def.
export const wrapPageElement: GatsbyBrowser<any, any>['wrapPageElement'] = ({ element, props }) => {
  const { location, pageContext } = props;
  const { pathname, search } = location;
  const { originalPath } = pageContext;

  const [, pathLocale] = pathname.split('/');

  // client side redirect on paths that don't have a locale in them. Most useful
  // on dev env where we don't have server redirects
  if (!isLanguage(pathLocale) && IS_DEV) {
    let detected =
      window.localStorage.getItem('eth-org-language') ||
      browserLang({
        languages: supportedLanguages,
        fallback: defaultLanguage
      });

    if (!isLanguage(detected)) {
      detected = defaultLanguage;
    }

    const queryParams = search || '';
    const newUrl = withPrefix(`/${detected}${originalPath}${queryParams}`);
    window.localStorage.setItem('eth-org-language', detected);

    window.location.replace(newUrl);

    return null;
  }
};