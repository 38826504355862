module.exports = [{
      plugin: require('../node_modules/gatsby-theme-material-ui-top-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":""},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"reslify.com","short_name":"reslify","start_url":"/","background_color":"#ffffff","theme_color":"#000000","display":"standalone","icon":"src/assets/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"531a0772bf94c1e71de1a4e12507bb7a"},
    },{
      plugin: require('../node_modules/gatsby-theme-i18n/gatsby-browser.js'),
      options: {"plugins":[],"defaultLang":"en","prefixDefault":true,"locales":"en tr","configPath":"/Users/ugurhicyilmam/Desktop/app.nosync/project/packages/landing/i18n/config.json","hrefLang":true},
    },{
      plugin: require('../node_modules/gatsby-theme-i18n-react-intl/gatsby-browser.js'),
      options: {"plugins":[],"defaultLocale":"./src/intl/en.json"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
